
import ImageMeta from "@/mixins/imageMeta.js";

export default {
  name: "ImageTextSection",
  mixins: [ImageMeta],
  props: ['zone'],
  data() {
    return {
      VideoPlayer: null,  // Define VideoPlayer as a data property
    };
  },
  computed: {
    imageAttributes() {
      return this.getAttribute(this.zone, 'image.data.attributes');
    },
    videoOptions() {
      return {
        autoplay: true,
        controls: false,
        loop: true,
        muted: true,
        sources: [
          {
            src: this.imageAttributes ? this.imageAttributes.url : '',
            type: this.imageAttributes ? this.imageAttributes.mime : '',
          }
        ]
      }
    }
  },
  created() {
    // Dynamically import the VideoPlayer component only when needed
    if (this.imageAttributes && this.imageAttributes.provider_metadata?.resource_type === 'video') {
      import('@/components/VideoPlayer.vue').then(module => {
        this.VideoPlayer = module.default;
      });
    }
  },
}
